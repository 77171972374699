import music from '../img/music/music.jpg'
import music1 from '../img/music/music1.jpg'
import music2 from '../img/music/music2.jpg'
import music3 from '../img/music/music3.jpg'
import music4 from '../img/music/music4.jpg'

const MusicPhotos = [
    {
        id: 1,
        photo: music
    },
    {
        id: 2,
        photo: music1
    },
    {
        id: 3,
        photo: music2
    },
    {
        id: 4,
        photo: music3
    },
    {
        id: 5,
        photo: music4
    },
]

export default MusicPhotos